import { Component, Inject, OnDestroy } from '@angular/core';
import { Controller } from '@models';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as ControllerActions from '../../store/actions/controller.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/store';

@Component({
  selector: 'add-controller-dialog',
  templateUrl: './add-controller-dialog.component.html',
  styleUrl: './add-controller-dialog.component.scss',
})
export class AddControllerDialogComponent implements OnDestroy {
  loading: boolean = false;
  controller: Controller;
  isNew: boolean;
  ipPattern: string = '^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\\.(?!$)|$)){4}$';
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<AddControllerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public controllerData: Controller,
  ) {
    this.isNew = !controllerData?.id;
    this.controller = controllerData || {};
  }

  onSubmit(): void {
    if (!this.controller.name || !this.controller.type || !this.controller.serialNumber || !this.controller.address || !this.controller.gateway || !this.controller.mask) {
      return;
    }

    this.controller.id ? this.store.dispatch(ControllerActions.updateController({ controller: this.controller })) : this.store.dispatch(ControllerActions.addController({ controller: this.controller }));

    this.dialogRef.close(this.controller);

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
