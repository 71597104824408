import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Account, AccountType } from '@models';
import { SharedService } from '@services';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import {
  AddAccountDialogComponent,
  ConfirmDialogComponent,
  ConfirmDialogType,
} from '@components';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { finalize, map, Observable, tap } from 'rxjs';
import { AppState } from '../../store/store';
import { select, Store } from '@ngrx/store';
import * as AccountActions from '../../store/actions/account.actions';
import * as AccountSelectors from '../../store/selectors/account.selectors';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
})
export class AccountsComponent implements OnInit {
  accounts$?: Observable<Account[] | undefined>;
  isLoading$?: Observable<boolean>;
  accounts?: Account[];
  displayedColumns: string[] = ['displayName', 'type', 'timestampCreated', 'actions'];
  getEnumValue: (enumType: any, key: string) => string = this.sharedService.getEnumValue;
  loading?: boolean;
  searchValue?: string;
  dataSource = new MatTableDataSource<Account>();
  pageSize = 10;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };
  };

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

initSubscriptions(): void {
    this.accounts$ = this.store.pipe(
      select(AccountSelectors.selectAccounts),
      tap(accounts => {
        if (!accounts) {
          this.store.dispatch(AccountActions.loadAccounts());
        }
      }),
      map(accounts => {
        this.accounts = accounts?.map(a => ({
          ...a,
          displayName: a.username || a.name || '',
        }));
        this.dataSource.data = this.accounts || [];
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        });
        return accounts;
      }),
    );
    this.isLoading$ = this.store.pipe(select(AccountSelectors.selectAccountIsLoading));
  }

  filterAccounts(val: string | undefined): void {
    this.searchValue = val;
    if (!val) {
      this.dataSource.data = this.accounts || [];
      return;
    }
    this.dataSource.data = this.accounts?.filter(a => ['displayName', 'timestampCreated']
        .some(k => a[k as keyof Account]?.toString().toLowerCase().includes(val.toLowerCase())) ||
      a.type && this.getEnumValue(AccountType, a.type).toString().toLowerCase().includes(val.toLowerCase())) || [];
  }

  showAddAccountDialog(): void {
    const dialogRef = this.dialog.open(AddAccountDialogComponent, {
      width: '500px',
    });
  }

  showDeleteDialog(account: Account): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: ConfirmDialogType.DELETE,
        title: 'Delete Account',
        message: `Are you sure you want to delete the account \'${account.displayName}\'?`,
        okText: 'Delete',
        cancelText: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.store.dispatch(AccountActions.deleteAccount({ id: account.id! }));
    });
  }

  protected readonly AccountType = AccountType;
  protected readonly faTrash = faTrash;
  protected readonly faPlusCircle = faPlusCircle;
}
