<div class="page cards">
  <ng-container *ngIf="{
        cards: cards$ | async,
        count: count$ | async,
        loading: isLoading$ | async
    } as data">
    <div class="container">
      <div class="header">
        <div class="title">Cards</div>
        <div class="page-actions">
          <button mat-raised-button color="primary" (click)="showManageCardDialog()">
            <fa-icon class="icon" [icon]="faPlusCircle"></fa-icon>
            <span>New Card</span>
          </button>
        </div>
      </div>
      <div class="content">
        <search-input [initialValue]="filters.search"
                      (searchInputChange)="updateSearchValue($event)"></search-input>
        <div class="loader" *ngIf="data.loading; else cardsData">
          <mat-spinner diameter="50" color="primary"></mat-spinner>
        </div>
        <ng-template #cardsData>
          <div *ngIf="data.cards">
            <table mat-table [dataSource]="data.cards">

              <ng-container matColumnDef="data">
                <th mat-header-cell *matHeaderCellDef>
                  Card Number
                </th>
                <td mat-cell *matCellDef="let card">{{ card.data }}</td>
              </ng-container>

              <!-- Type Column -->
              <ng-container matColumnDef="type">
                <th mat-header-cell class="text-center" *matHeaderCellDef>Type
                </th>
                <td mat-cell class="text-center" *matCellDef="let card">{{ card.type }}
                </td>
              </ng-container>

              <ng-container matColumnDef="user">
                <th mat-header-cell class="text-center" *matHeaderCellDef>User
                </th>
                <td mat-cell class="text-center" *matCellDef="let card">
                  <empty-icon *ngIf="!card?.user?.name"></empty-icon>
                  <a [routerLink]="['/users', card?.user?.id]">{{ card?.user?.name }}</a>
                </td>
              </ng-container>

              <ng-container matColumnDef="notes">
                <th mat-header-cell class="text-center" *matHeaderCellDef>Notes</th>
                <td mat-cell class="notes-column text-center" *matCellDef="let card; let i = index">
                  <empty-icon *ngIf="!card.notes?.length"></empty-icon>
                  <div
                    (click)="extendNotes[i] = !extendNotes[i]">{{ card.notes | charLimit: (extendNotes[i] ? undefined : 100) }}
                    <span *ngIf="card.notes?.length > 100 && extendNotes[i]">{{ '[-]' }}</span>
                  </div>
                </td>
              </ng-container>

              <!-- Timestamp Created Column -->
              <ng-container matColumnDef="timestampCreated">
                <th mat-header-cell class="text-center" *matHeaderCellDef>Created At
                </th>
                <td mat-cell class="text-center"
                    *matCellDef="let card">{{ card.timestampCreated | date: 'MMM dd, YYYY HH:mm' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell class="text-center" *matHeaderCellDef></th>
                <td mat-cell class="text-center" *matCellDef="let card">
                  <div class="text-sm">
                    <button class="icon-button" mat-icon-button title="Delete" (click)="showDeleteDialog(card)">
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <!-- Column definitions -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="no-data-cell" [attr.colspan]="displayedColumns.length">
                  No Results
                </td>
              </tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <mat-paginator [pageSizeOptions]="[10, 20, 40, 100]"
                         [pageSize]="filters.pageSize"
                         [pageIndex]="filters.pageOffset"
                         [showFirstLastButtons]="true"
                         [length]="data.count"
                         (page)="onPageFiltersChange($event)"
                         aria-label="Select page of periodic elements">
          </mat-paginator>
        </ng-template>
      </div>
    </div>
  </ng-container>
</div>
