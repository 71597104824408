import { Account } from '@models';
import { createReducer, on } from '@ngrx/store';
import * as AccountActions from '../actions/account.actions';

export interface AccountState {
  data?: Account[];
  loading: boolean;
  error: string;
  me?: Account;
}

export const initialAccountState: AccountState = {
  loading: false,
  error: '',
};

const reducer = createReducer<AccountState>(
  initialAccountState,
  on(AccountActions.loadAccounts, (state) => ({ ...state, loading: true })),
  on(AccountActions.loadAccountsSuccess, (state, { data }) => ({ ...state, data: data, loading: false })),
  on(AccountActions.loadAccountsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(AccountActions.addAccount, (state, { account }) => ({ ...state, loading: true })),
  on(AccountActions.addAccountSuccess, (state, { account }) => ({
    ...state,
    data: state.data ? [...state.data, account] : [account],
    loading: false,
  })),
  on(AccountActions.addAccountFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(AccountActions.deleteAccount, (state, { id }) => ({ ...state, loading: true })),
  on(AccountActions.deleteAccountSuccess, (state, { id }) => ({
    ...state,
    data: state.data?.filter(a => a.id !== id),
    loading: false,
  })),
  on(AccountActions.deleteAccountFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(AccountActions.loadMeSuccess, (state, { data }) => ({ ...state, me: data })),
  on(AccountActions.loadMeFailure, (state, { error }) => ({ ...state, error })),
  on(AccountActions.removeMe, (state) => ({ ...state, me: undefined }))
);

export function accountReducer(state = initialAccountState, action: any): AccountState {
  return reducer(state, action);
}
