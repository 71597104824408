import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, Observable, Subject, takeUntil, tap } from 'rxjs';
import { Activity, Project } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/store';
import { SharedService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import * as ActivitySelectors from '../../store/selectors/activity.selectors';
import * as ActivityActions from '../../store/actions/activity.actions';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import * as ProjectSelectors from '../../store/selectors/project.selectors';
import * as ProjectActions from '../../store/actions/project.actions';
import { format } from 'date-fns';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'activities',
  templateUrl: './activities.component.html',
  styleUrl: './activities.component.scss',
})
export class ActivitiesComponent implements OnInit, OnDestroy {
  activities$: Observable<Activity[] | undefined> = this.store.select(ActivitySelectors.selectActivities);
  isLoading$?: Observable<boolean> = this.store.select(ActivitySelectors.selectActivityIsLoading);
  displayedColumns: string[] = ['timestamp', 'event', 'user', 'site', 'controller', 'actions'];
  count$: Observable<number | undefined> = this.store.select(ActivitySelectors.selectActivityCount);
  filters: any;
  dataSource = new MatTableDataSource<Activity>();
  private destroy$ = new Subject<void>();
  private searchSubject = new Subject<string>();

  projects?: Project[];
  filteredProjects: Project[] = [];
  dateFrom: Date | null = null;
  dateTo: Date | null = null;
  projectId?: number[];
  status?: string;

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  ngOnInit(): void {
    this.store.pipe(
      select(ProjectSelectors.selectProjects),
      tap(projects => {
        if (!projects || projects.length === 0) {
          this.store.dispatch(ProjectActions.loadProjects());
        }
      }),
      takeUntil(this.destroy$),
    ).subscribe(projects => {
      this.projects = projects || [];
      this.filteredProjects = [...this.projects];
    });

    this.route.queryParams.pipe(
      takeUntil(this.destroy$),
    ).subscribe(params => {
      this.filters = {
        ...params,
        dateFrom: params['dateFrom'] ?? undefined,
        dateTo: params['dateTo'] ?? undefined,
        projectId: params['projectId'] ? params['projectId'].split(',').map(Number) : undefined,
        status: params['status'] || undefined,
        search: params['search'] || undefined,
        pageSize: +params['pageSize'] || 10,
        pageOffset: +params['pageOffset'] || 0,
      };
      this.dateFrom = this.filters.dateFrom ? new Date(this.filters.dateFrom) : null;
      this.dateTo = this.filters.dateTo ? new Date(this.filters.dateTo) : null;
      this.projectId = this.filters.projectId;
      this.status = this.filters.status ?? 'all';
      this.updateFilters(this.filters);
    });

    this.store.select(ActivitySelectors.selectActivityFilters).pipe(
      takeUntil(this.destroy$),
    ).subscribe(filters => {
      this.filters = filters;
      this.sharedService.updateParams(filters);
    });

    // Listen for search value changes with debouncing
    this.searchSubject.pipe(
      debounceTime(500),
      takeUntil(this.destroy$),
    ).subscribe(searchValue => {
      if (this.filters.search !== searchValue) {
        this.filters = { ...this.filters, search: searchValue };
        this.updateFilters(this.filters);
      }
    });

  }

  onPageFiltersChange(event: any): void {
    this.filters = { ...this.filters, pageSize: event.pageSize, pageOffset: event.pageIndex };
    this.updateFilters(this.filters);
  }

  updateSearchValue(searchValue: string): void {
    this.searchSubject.next(searchValue);
  }

  updateFilters(filters: any): void {
    this.sharedService.updateParams(filters);
    this.store.dispatch(ActivityActions.updateActivityFilters({ filters }));
  }

  filterProjects(searchQuery: string) {
    const query = searchQuery.toLowerCase().trim();
    this.filteredProjects = this.projects?.filter((project) =>
      project.name?.toLowerCase().includes(query),
    ) || [];
  }

  onProjectChange() {
    this.updateFilters({ ...this.filters, projectId: this.projectId });
  }

  // Date filter for "From" picker (disable dates after "To")
  dateFromFilter = (date: Date | null): boolean => {
    if (!this?.dateTo) {
      return true; // No "To" date selected, all dates are valid
    }
    return !date || date <= this.dateTo;
  };

  // Date filter for "To" picker (disable dates before "From")
  dateToFilter = (date: Date | null): boolean => {
    if (!this?.dateFrom) {
      return true; // No "From" date selected, all dates are valid
    }
    return !date || date >= this.dateFrom;
  };

  onDateFromChange() {
    this.dateToFilter = this.dateToFilter.bind(this);
    this.updateFilters({ ...this.filters, dateFrom: this.dateFrom ? format(this.dateFrom, 'yyyy-MM-dd') : undefined });
  }

  onDateToChange() {
    this.dateFromFilter = this.dateFromFilter.bind(this);
    this.updateFilters({ ...this.filters, dateTo: this.dateTo ? format(this.dateTo, 'yyyy-MM-dd') : undefined });
  }

  onStatusChange(event: any) {
    this.updateFilters({ ...this.filters, status: event.value });
  }

  areFiltersApplied(): boolean {
    return !!this.filters?.search || !!this.filters?.dateFrom || !!this.filters?.dateTo || !!this.filters?.projectId?.length || (!!this.filters?.status && this.filters?.status !== 'all');
  }

  clearFilters(): void {
    this.updateFilters({});
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly faPlusCircle = faPlusCircle;
}
