<div class="flex justify-between items-baseline">
  <h2 mat-dialog-title>Add New Account</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon class="text-white">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Select Type</mat-label>
    <mat-select [(value)]="account.type" required>
      <mat-option value="PERSON">User</mat-option>
      <mat-option value="SYSTEM">Service</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="flex gap-x-4" *ngIf="!!account.type">

    <ng-container *ngIf="account.type === 'PERSON'">
      <mat-form-field class="flex-1" appearance="fill">
        <mat-label>Username</mat-label>
        <div class="flex">
          <input matInput placeholder="Username" [(ngModel)]="account.identifier" required>
          <button mat-icon-button matSuffix disabled></button>
        </div>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="account.type === 'SYSTEM'">
      <mat-form-field class="flex-1" appearance="fill">
        <mat-label>Service Name</mat-label>
        <div class="flex">
          <input matInput placeholder="Service Name" [(ngModel)]="account.identifier" required>
          <button mat-icon-button matSuffix disabled></button>
        </div>
      </mat-form-field>
    </ng-container>

    <ng-container>
      <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <div class="flex">
          <input matInput type="{{ hidePassword ? 'password' : 'text' }}" placeholder="Password"
                 [(ngModel)]="account.key" required>
          <button mat-icon-button matSuffix (click)="togglePasswordVisibility()">
            <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </div>
      </mat-form-field>
    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [disabled]="loading" (click)="onCancelClick()">Cancel</button>
  <button mat-button color="primary" [disabled]="loading" (click)="onSubmit()">
    <span class="font-semibold" *ngIf="!loading">Add</span>
    <span class="flex gap-x-1" *ngIf="loading">Adding...<mat-spinner *ngIf="loading" diameter="20"
                                                                     color="accent"></mat-spinner></span>
  </button>
</mat-dialog-actions>
