<div class="content">
  <ng-container *ngIf="{
        projectControllers: projectControllers$ | async,
        loading: isLoading$ | async
    } as data">
    <div class="loader" *ngIf="data.loading; else projectControllersData">
      <mat-spinner diameter="50" color="primary"></mat-spinner>
    </div>
    <ng-template #projectControllersData>
      <div class="flex justify-end mb-4">
        <button mat-raised-button color="primary" (click)="showAddProjectControllersDialog()">
          <fa-icon class="icon" [icon]="faPlusCircle"></fa-icon>
          <span>Add Controllers</span>
        </button>
      </div>
      <div *ngIf="data.projectControllers">
        <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Name</th>
            <td mat-cell *matCellDef="let projectController">
              <a [routerLink]="['/controllers', projectController.id]">{{ projectController.name }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef>S/N</th>
            <td mat-cell *matCellDef="let projectController">{{ projectController.serialNumber }}</td>
          </ng-container>

          <ng-container matColumnDef="dateAddedToProject">
            <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by date Added To Project">Added At
            </th>
            <td mat-cell class="text-center"
                *matCellDef="let projectController">{{ projectController?.dateAddedToProject | date: 'MMM dd, YYYY' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let projectController">{{ projectController.type }}</td>
          </ng-container>

          <!-- Timestamp Created Column -->
          <ng-container matColumnDef="timestampCreated">
            <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by datetime created">Created At
            </th>
            <td mat-cell class="text-center"
                *matCellDef="let projectController">{{ projectController.timestampCreated | date: 'MMM dd, YYYY HH:mm' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell class="text-center" *matHeaderCellDef></th>
            <td mat-cell class="flex gap-x-1 text-center" *matCellDef="let projectController">
              <div class="text-sm">
                <button class="icon-button" mat-icon-button title="Remove" (click)="showRemoveDialog(projectController)">
                  <fa-icon class="remove-icon" [icon]="faCircleMinus"></fa-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <!-- Column definitions -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="no-data-cell" [attr.colspan]="displayedColumns.length">
              No Results
            </td>
          </tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

      </div>
    </ng-template>
  </ng-container>
</div>
