import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as UserActions from '../actions/user.actions';
import * as UserSelectors from '../selectors/user.selectors';
import { catchError, map, mergeMap, of, tap, withLatestFrom } from 'rxjs';
import { SharedService, UserService } from '@services';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class UserEffects {

  loadUsers$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.updateUserFilters),
    withLatestFrom(this.store.select(UserSelectors.selectUserFilters)),
    mergeMap(data => this.userService.getUsers(data[1]).pipe(
        map((data) => UserActions.loadUsersSuccess({ data })),
        catchError(error => of(UserActions.loadUsersFailure({ error }))),
      ),
    ),
  ));

  loadUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.loadUser),
    mergeMap(action => this.userService.getUser(action.id).pipe(
      map((user) => UserActions.loadUserSuccess({ user })),
      catchError(error => of(UserActions.loadUserFailure({ error }))),
    )),
  ));

  addUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.addUser),
      mergeMap(action =>
        this.userService.addUser(action.user).pipe(
          map(response => {
            // Assuming response contains the newly added User
            this.sharedService.showSuccessSnackbar('User added successfully');
            return UserActions.addUserSuccess({ user: response });
          }),
          catchError(error => of(UserActions.addUserFailure({ error }))),
        ),
      ),
    ),
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUser),
      mergeMap(action =>
        this.userService.updateUser(action.user).pipe(
          map(response => {
            // Assuming response contains the updated User
            this.sharedService.showSuccessSnackbar('User updated successfully');
            return UserActions.updateUserSuccess({ user: response });
          }),
          catchError(error => of(UserActions.updateUserFailure({ error }))),
        ),
      ),
    ),
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteUser),
      mergeMap(action =>
        this.userService.deleteUser(action.id).pipe(
          map(() => {
            this.sharedService.showSuccessSnackbar('User deleted successfully');
            return UserActions.deleteUserSuccess({ id: action.id });
          }),
          catchError(error => of(UserActions.deleteUserFailure({ error }))),
        ),
      ),
    ),
  );

  updateUserList$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(
          UserActions.addUserSuccess,
          UserActions.updateUserSuccess,
          UserActions.deleteUserSuccess,
        ),
        withLatestFrom(this.store.select(UserSelectors.selectUserFilters)), // Select filters from the state
        map(([action, filters]) => {
          return UserActions.updateUserFilters({ filters: filters });
        }), // Dispatch loadUsers with the filters
      )
    }
  );

  loadUserGroups$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.loadUserGroups),
    mergeMap((action) => this.userService.getUserGroups(action.userId).pipe(
      map((data) => UserActions.loadUserGroupsSuccess({ userId: action.userId, groups: data })),
      catchError(error => of(UserActions.loadUserGroupsFailure({ error }))),
    )),
  ));

  addUserGroups$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.addUserGroups),
    mergeMap((action) => this.userService.addUserGroups(action.userId, action.groups).pipe(
      map((data) => {
        this.sharedService.showSuccessSnackbar('Groups added successfully');
        return UserActions.addUserGroupsSuccess({ data });
      }),
      catchError(error => of(UserActions.addUserGroupsFailure({ error }))),
    )),
  ));

  deleteUserGroups$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.deleteUserGroups),
    mergeMap((action) => this.userService.deleteUserGroups(action.userId, action.groups).pipe(
      map(() => {
        this.sharedService.showSuccessSnackbar('Group removed successfully');
        return UserActions.deleteUserGroupsSuccess({ data: action.groups });
      }),
      catchError(error => of(UserActions.deleteUserGroupsFailure({ error }))),
    )),
  ));

  loadUserCards$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.loadUserCards),
    mergeMap((action) => this.userService.getUserCards(action.userId).pipe(
      map((data) => UserActions.loadUserCardsSuccess({ userId: action.userId, cards: data })),
      catchError(error => of(UserActions.loadUserCardsFailure({ error }))),
    )),
  ));

  addUserCards$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.addUserCards),
    mergeMap((action) => this.userService.addUserCards(action.userId, action.cards).pipe(
      map((data) => {
        this.sharedService.showSuccessSnackbar('Cards added successfully');
        return UserActions.addUserCardsSuccess({ data });
      }),
      catchError(error => of(UserActions.addUserCardsFailure({ error }))),
    )),
  ));

  deleteUserCards$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.deleteUserCards),
    mergeMap((action) => this.userService.deleteUserCards(action.userId, action.cards).pipe(
      map(() => {
        this.sharedService.showSuccessSnackbar('Card removed successfully');
        return UserActions.deleteUserCardsSuccess({ data: action.cards });
      }),
      catchError(error => of(UserActions.deleteUserCardsFailure({ error }))),
    )),
  ));

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private sharedService: SharedService,
    private store: Store,
  ) {
  }
}
