import { Activity } from '@models';
import { createReducer, on } from '@ngrx/store';
import * as ActivityActions from '../actions/activity.actions';

export interface ActivityState {
  data?: { count: number, data: Activity[] };
  loading: boolean;
  error: string;
  filters: any;
}

export const initialActivityState: ActivityState = {
  loading: false,
  error: '',
  filters: {},
};

const reducer = createReducer<ActivityState>(
  initialActivityState,
  on(ActivityActions.loadActivities, (state) => ({ ...state, loading: true })),
  on(ActivityActions.loadActivitiesSuccess, (state, { data }) => ({ ...state, data: data, loading: false })),
  on(ActivityActions.loadActivitiesFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ActivityActions.updateActivityFilters, (state, { filters }) => ({
    ...state,
    filters,
    loading: true,
  })),
);

export function activityReducer(state = initialActivityState, action: any): ActivityState {
  return reducer(state, action);
}
