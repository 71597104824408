
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../reducers/user.reducers';

export const selectUserState = createFeatureSelector<UserState>('user');
export const selectUsers = createSelector(selectUserState, (state: UserState) => state.data?.data);
export const selectUserCount = createSelector(selectUserState, (state: UserState) => state.data?.count);
export  const selectUserFilters = createSelector(selectUserState, (state: UserState) => state.filters);
export const selectSelectedUser = createSelector(selectUserState, (state: UserState) => state.selectedUser);
export const selectSelectedUserGroups = createSelector(selectUserState, (state: UserState) => state.selectedUser?.groups);
export const selectSelectedUserCards = createSelector(selectUserState, (state: UserState) => state.selectedUser?.cards);
export const selectUserIsLoading = createSelector(selectUserState, (state: UserState) => state.loading);
