import { createAction, props } from '@ngrx/store';
import { Activity } from '@models';

export const loadActivities = createAction('[Activity] Load Activities', props<{ filters?: any }>());
export const loadActivitiesSuccess = createAction('[Activity] Load Activities Success', props<{
  data: { count: number, data: Activity[] }
}>());
export const loadActivitiesFailure = createAction('[Activity] Load Activities Failure', props<{ error: any }>());

export const updateActivityFilters = createAction('[Activity] Update Filters', props<{ filters?: any }>());
