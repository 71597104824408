import { Component } from '@angular/core';

@Component({
  selector: 'empty-icon',
  templateUrl: './empty-icon.component.html',
  styleUrl: './empty-icon.component.scss'
})
export class EmptyIconComponent {

}
