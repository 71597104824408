import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { Card, User } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/store';
import { SharedService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as UserSelectors from '../../../../store/selectors/user.selectors';
import * as UserActions from '../../../../store/actions/user.actions';
import * as CardSelectors from '../../../../store/selectors/card.selectors';
import * as CardActions from '../../../../store/actions/card.actions';
import { AddOptionsDialogComponent, ConfirmDialogComponent, ConfirmDialogType } from '@components';
import { faCircleMinus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { CardService } from '../../../../services';

@Component({
  selector: 'user-cards',
  templateUrl: './user-cards.component.html',
  styleUrl: './user-cards.component.scss'
})
export class UserCardsComponent implements OnInit, OnDestroy {
  userCards$?: Observable<Card[] | undefined>
  isLoading$?: Observable<boolean>
  userCards: Card[] = [];
  cards: Card[] = [];
  userId?: number;
  user: User = {} as User;
  displayedColumns: string[] = ['data', 'type', 'notes', 'timestampCreated', 'actions'];
  dataSource = new MatTableDataSource<Card>();
  private unsubscribe$ = new Subject<void>();

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.dataSource.sortingDataAccessor = this.sharedService.sortingDataAccessorForNestedProperties;
  };

  constructor(
    private store: Store<AppState>,
    private cardService: CardService,
    private sharedService: SharedService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
  ) {
    this.store.pipe(select(UserSelectors.selectSelectedUser)).subscribe(user => this.user = user!);
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initSubscriptions(): void {
    this.route.parent?.paramMap.pipe(
      map(params => params.get('id')),
      tap(id => {
        if (id) {
          this.userId = +id;
          this.userCards$ = this.store.pipe(
            select(UserSelectors.selectSelectedUserCards),
            tap(usersCards => {
              if (!usersCards) {
                this.store.dispatch(UserActions.loadUserCards({ userId: +id }));
              }
            }),
            map(userCards => {
              this.userCards = (userCards || []).map(pg => ({ ...pg, notesCharLimit: 100 }));
              this.dataSource.data = this.userCards;
              return userCards;
            })
          );
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe();

    this.store.pipe(
      select(CardSelectors.selectCards),
      tap(cards => {
        if (!cards || cards.length === 0) {
          this.store.dispatch(CardActions.loadCards({}));
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe(cards => {
      this.cards = cards || [];
    });

    this.isLoading$ = this.store.pipe(
      select(UserSelectors.selectUserIsLoading),
      takeUntil(this.unsubscribe$),
    );
  }

  showAddUserCardsDialog(): void {
    const dialogRef = this.dialog.open(AddOptionsDialogComponent, {
      width: '400px',
      data: {
        options$: this.cardService.getCards.bind(this.cardService),
        displayProperty: 'data',
        title: 'Select Cards'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(UserActions.addUserCards({ userId: this.userId!, cards: result }));
      }
    });
  }

  showRemoveDialog(card: Card): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: ConfirmDialogType.DELETE,
        title: 'Remove Card',
        message: `Are you sure you want to remove card \'${card.data}\' from \'${this.user.name}\'?`,
        okText: 'Remove',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(UserActions.deleteUserCards({ userId: this.user.id!, cards: [card] }));
      }
    });
  }

  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faCircleMinus = faCircleMinus;
}
