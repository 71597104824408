import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, Observable, Subject, tap } from 'rxjs';
import { isEqual } from 'lodash';
import { MatSelectionListChange } from '@angular/material/list';

interface PaginatedData<T> {
  count: number;
  data: T[];
}

@Component({
  selector: 'add-options-dialog',
  templateUrl: './add-options-dialog.component.html',
  styleUrl: './add-options-dialog.component.scss',
})
export class AddOptionsDialogComponent<T> implements OnInit {

  options: T[] = [];
  filteredOptions: T[] = [];
  selected: T[] = [];
  searchInput$ = new Subject<string>(); // To track search input changes
  searchValue: string = '';
  totalItems: number = 0;
  isLoading: boolean = false;
  hasMore: boolean = true;
  filters: any = {
    search: '',
    pageOffset: 0,
    pageSize: 40,
  };


  constructor(
    public dialogRef: MatDialogRef<AddOptionsDialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: {
      multiple?: boolean,
      options: T[],
      displayProperty?: keyof T,
      displayFunction?: (option: T) => string,
      title: string,
      options$?: (filters: any) => Observable<PaginatedData<T>> // The service call
    },
  ) {
  }

  ngOnInit(): void {
    this.loadInitialOptions();
    this.handleSearch();
  }

  loadInitialOptions(): void {
    if (this.data.options$) {
      // If observable is provided, load options via infinite scroll
      this.loadOptions();
    } else if (this.data.options) {
      // Use static options array if provided
      this.options = this.data.options;
      this.filteredOptions = [...this.options];
      this.totalItems = this.options.length;
      this.hasMore = false;
    }
  }

  loadOptions(reset: boolean = false): void {
    if (reset) {
      this.filters.pageOffset = 0; // Reset page offset when the search changes
      this.options = []; // Clear previous options
      this.hasMore = true; // Reset the flag to allow loading more
    }

    if (this.isLoading || !this.hasMore) {
      return;
    } // Prevent duplicate requests

    this.isLoading = true;

    this.data.options$!({ ...this.filters, search: this.searchValue })
      .pipe(
        tap(response => {
          this.totalItems = response.count; // Update total items count from server
          this.options = [...this.options, ...response.data]; // Append new data to options
          this.hasMore = this.options.length < this.totalItems; // Stop if all data is loaded
          this.filters.pageOffset++; // Increase pageOffset for the next load
        }),
      )
      .subscribe({
        next: () => this.isLoading = false,
        error: () => this.isLoading = false,
      });
  }

  getDisplayValue(option: T): string {
    if (this.data.displayProperty) {
      return option[this.data.displayProperty] as string;
    }
    if (this.data.displayFunction) {
      return this.data.displayFunction(option);
    }
    return option as string;
  }

  handleSearch(): void {
    if (this.data.options$) {
      this.searchInput$.pipe(
        debounceTime(500), // Debounce to handle search input
        distinctUntilChanged(), // Only emit if the search value has changed
        tap(() => this.loadOptions(true)), // Reset and reload options on search change
      ).subscribe();
    }
  }

  addToSelected(event: MatSelectionListChange): void {
    this.selected.push(event.source.selectedOptions.selected.at(-1)?.value);
  }

  removeFromSelected(event: MatSelectionListChange): void {
    this.selected = event.source.selectedOptions.selected.map(option => option.value);
  }

  optionIsSelected(option: T): boolean {
    return this.selected.some(sel => isEqual(sel, option));
  }

  onSearchInputChanged(newValue: string): void {
    this.searchInput$.next(newValue.toLowerCase()); // Emit search input value to Subject
    this.searchValue = newValue;
    if (this.data.options) {
      this.filteredOptions = this.options.filter(option => this.getDisplayValue(option).toLowerCase().includes(newValue.toLowerCase()));
    }
  }

  onConfirm(): void {
    if (!this.selected?.length) {
      return;
    }
    this.dialogRef.close(this.selected);
  }

  // Triggered when the user scrolls down
  onScroll(): void {
    if (this.data.options$) {
      this.loadOptions(); // Only load more when using the service call
    }
  }
}
