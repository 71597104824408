import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs';
import { Action } from '@ngrx/store';
import { ErrorSnackbarComponent } from '@components';

interface FailureAction extends Action {
  error: { error: { error: string, message: string }, status: number, message: string };
}

@Injectable({
  providedIn: 'root',
})
export class ResponseHandlingEffects {
  constructor(private actions$: Actions, private snackBar: MatSnackBar) {
  }

  showError$ = createEffect(() =>
      this.actions$.pipe(
        tap(action => {
          if (action.type.endsWith('Failure')) {
            const failureAction = action as FailureAction;
            this.snackBar.openFromComponent(ErrorSnackbarComponent, {
              data: { message: `Error: ${failureAction.error.error?.message || failureAction.error.message}` },
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: ['error-snackbar']
            });
          }
        }),
      ),
    { dispatch: false }, // This effect does not dispatch another action
  );
}
