import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AccountActions from '../actions/account.actions';
import { catchError, map, mergeMap, of } from 'rxjs';
import { AccountService, SharedService } from '@services';

@Injectable({
  providedIn: 'root'
})
export class AccountEffects {

  loadAccounts$ = createEffect(() => this.actions$.pipe(
    ofType(AccountActions.loadAccounts),
    mergeMap(() => this.accountService.getAccounts().pipe(
      map((data) => AccountActions.loadAccountsSuccess({ data })),
      catchError(error => of(AccountActions.loadAccountsFailure({ error })))
    ))
  ));

  addAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.addAccount),
      mergeMap(action =>
        this.accountService.addAccount(action.account).pipe(
          map(response => {
            // Assuming response contains the newly added account
            this.sharedService.showSuccessSnackbar('Account added successfully');
            return AccountActions.addAccountSuccess({ account: response });
          }),
          catchError(error => of(AccountActions.addAccountFailure({ error })))
        )
      )
    )
  );

  deleteAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.deleteAccount),
      mergeMap(action =>
        this.accountService.deleteAccount(action.id).pipe(
          map(() => {
            this.sharedService.showSuccessSnackbar('Account deleted successfully');
            return AccountActions.deleteAccountSuccess({ id: action.id });
          }),
          catchError(error => of(AccountActions.deleteAccountFailure({ error })))
        )
      )
    )
  );

  loadMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.loadMe),
      mergeMap(() => this.accountService.getMe().pipe(
        map((data) => AccountActions.loadMeSuccess({ data })),
        catchError(error => of(AccountActions.loadMeFailure({ error })))
      ))
    )
  );

  constructor(
    private actions$: Actions,
    private accountService: AccountService,
    private sharedService: SharedService
  ) { }
}
