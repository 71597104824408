<h2 mat-dialog-title>{{ data.title }}</h2>
<search-input class="mr-4 ml-4" (searchInputChange)="onSearchInputChanged($event)"></search-input>
<mat-dialog-content>
  <cdk-virtual-scroll-viewport class="scrollable-container" *ngIf="data.options$ else optionsAsArray" itemSize="40"
                               (scrolledIndexChange)="onScroll()">
    <mat-selection-list [multiple]="data.multiple !== false" (selectionChange)="removeFromSelected($event)"
                        *ngIf="selected?.length">
      <mat-list-option
        *ngFor="let selection of selected"
        [value]="selection"
        [selected]="true">
        {{ getDisplayValue(selection) }}
      </mat-list-option>
    </mat-selection-list>
    <mat-selection-list [multiple]="data.multiple !== false" (selectionChange)="addToSelected($event)">
      <mat-list-option
        *ngFor="let option of options"
        [value]="option"
        [selected]="false"
        [hidden]="optionIsSelected(option)">
        {{ getDisplayValue(option) }}
      </mat-list-option>
    </mat-selection-list>
    <div class="no-results" *ngIf="!selected.length && !options.length && !isLoading">
      Empty
    </div>
    <div class="flex justify-center items-center" *ngIf="isLoading">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
  </cdk-virtual-scroll-viewport>

  <ng-template #optionsAsArray>
    <mat-selection-list [(ngModel)]="selected" [multiple]="data.multiple !== false">
      <mat-list-option *ngFor="let option of options" [value]="option"
                       [hidden]="!getDisplayValue(option).toLowerCase().includes(searchValue)">
        <div [innerHTML]="getDisplayValue(option)"></div>
      </mat-list-option>
    </mat-selection-list>
  </ng-template>

  <div class="no-results" *ngIf="!filteredOptions.length && !isLoading">
    Empty
  </div>
  <div class="flex justify-center items-center" *ngIf="isLoading">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
  <!-- Loading spinner when fetching -->
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="dialogRef.close()">Cancel</button>
  <button mat-button color="primary" (click)="onConfirm()">Confirm</button>
</mat-dialog-actions>
