import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddOptionsDialogComponent } from '../add-options-dialog/add-options-dialog.component';

@Component({
  selector: 'select-multiple-popup',
  templateUrl: './select-multiple-popup.component.html',
  styleUrl: './select-multiple-popup.component.scss'
})
export class SelectMultiplePopupComponent<T> {
  @Input() options: T[] = [];
  @Input() model: T | T[] | undefined;
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() placeholder: string = 'Select';
  @Input() displayProperty?: string;
  @Input() selectProperty?: string;
  @Input() trackBy?: string;
  @Input() title?: string;
  @Input() multiple: boolean = true;
  @Output() modelChange: EventEmitter<T | T[]> = new EventEmitter();

  constructor(public dialog: MatDialog) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(AddOptionsDialogComponent, {
      width: '400px',
      data: { options: this.options, multiple: this.multiple, displayProperty: this.displayProperty, title: this.title }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.model = result;
        this.modelChange.emit(this.model);
      }
    });
  }

  /** Helper method to display selected values */
  getSelectedDisplay(): string {
    if (this.multiple) {
      const selectedArray = this.model as T[];
      return selectedArray?.length
        ? selectedArray
          .map(option => (this.displayProperty ? option[this.displayProperty as keyof T] : option))
          .join(', ')
        : '';
    }
    const selected = this.model as T;
    return (selected ? (this.displayProperty ? selected[this.displayProperty as keyof T] : selected) : '') as string;
  }

  /** Helper method to determine if there are any selected values */
  hasSelection(): boolean {
    if (this.multiple) {
      const selectedArray = this.model as T[];
      return selectedArray?.length > 0;
    } else {
      return !!this.model;
    }
  }

  clearSelection(): void {
    this.model = this.multiple ? [] : undefined;
    this.modelChange.emit(this.model);
  }


}
