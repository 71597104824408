import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {NavigationService} from "@services";

@Component({
  selector: 'arrow-back',
  templateUrl: './arrow-back.component.html',
  styleUrls: ['./arrow-back.component.scss']
})
export class ArrowBackComponent {

  @Input() public goTo: any;
  @Input() public defaultOption: any;

  constructor(private router: Router, private navigationService: NavigationService) {

  }


  goBack(): void {
    if (this?.goTo?.length) {
      this.router.navigate(this.goTo)
    } else {
      this.navigationService.back(this.defaultOption);
    }
  }

}
