import { User } from '@models';
import { createReducer, on } from '@ngrx/store';
import * as UserActions from '../actions/user.actions';

export interface UserState {
  data?: { count: number, data: User[] };
  selectedUser: User | null;
  loading: boolean;
  filters: any;
  error: string;
}

export const initialUserState: UserState = {
  selectedUser: null,
  loading: false,
  filters: {},
  error: '',
};

const reducer = createReducer<UserState>(
  initialUserState,
  on(UserActions.loadUsers, (state) => ({ ...state, loading: true })),
  on(UserActions.loadUsersSuccess, (state, { data }) => ({ ...state, data: data, loading: false })),
  on(UserActions.loadUsersFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(UserActions.updateUserFilters, (state, { filters }) => ({
    ...state,
    filters,
    loading: true,
  })),
  on(UserActions.loadUser, (state) => ({ ...state, loading: true })),
  on(UserActions.loadUserSuccess, (state, { user }) => ({ ...state, selectedUser: user, loading: false })),
  on(UserActions.loadUserFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(UserActions.addUser, (state) => ({ ...state, loading: true })),
  on(UserActions.addUserSuccess, (state) => ({ ...state, loading: false })),
  on(UserActions.addUserFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(UserActions.updateUser, (state, { user }) => ({
    ...state,
    loading: true,
  })),
  on(UserActions.updateUserSuccess, (state) => ({ ...state, loading: false })),
  on(UserActions.updateUserFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(UserActions.deleteUser, (state, { id }) => ({ ...state, loading: true })),
  on(UserActions.deleteUserSuccess, (state) => ({ ...state, loading: false })),
  on(UserActions.deleteUserFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(UserActions.loadUserGroups, (state) => ({ ...state, loading: true })),
  on(UserActions.loadUserGroupsSuccess, (state, data) => {
    return {
      ...state,
      selectedUser: { ...state.selectedUser, groups: data.groups },
      loading: false,
    };
  }),
  on(UserActions.loadUserGroupsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(UserActions.addUserGroups, (state) => ({ ...state, loading: true })),
  on(UserActions.addUserGroupsSuccess, (state, { data }) => {
    return {
      ...state,
      selectedUser: { ...state.selectedUser, groups: data },
      loading: false,
    };
  }),
  on(UserActions.addUserGroupsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(UserActions.deleteUserGroups, (state) => ({ ...state, loading: true })),
  on(UserActions.deleteUserGroupsSuccess, (state, { data }) => {
    return {
      ...state,
      selectedUser: { ...state.selectedUser, groups: state.selectedUser?.groups?.filter(g => !data.some(ug => ug.id === g.id)) },
      loading: false,
    };
  }),
  on(UserActions.deleteUserGroupsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(UserActions.loadUserCards, (state) => ({ ...state, loading: true })),
  on(UserActions.loadUserCardsSuccess, (state, data) => {
    return {
      ...state,
      selectedUser: { ...state.selectedUser, cards: data.cards },
      loading: false,
    };
  }),
  on(UserActions.loadUserCardsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(UserActions.addUserCards, (state) => ({ ...state, loading: true })),
  on(UserActions.addUserCardsSuccess, (state, { data }) => {
    return {
      ...state,
      selectedUser: { ...state.selectedUser, cards: data },
      loading: false,
    };
  }),
  on(UserActions.addUserCardsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(UserActions.deleteUserCards, (state) => ({ ...state, loading: true })),
  on(UserActions.deleteUserCardsSuccess, (state, { data }) => {
    return {
      ...state,
      selectedUser: { ...state.selectedUser, cards: state.selectedUser?.cards?.filter(c => !data.some(uc => uc.id === c.id)) },
      loading: false,
    };
  }),
  on(UserActions.deleteUserCardsFailure, (state, { error }) => ({ ...state, loading: false, error })),
);

export function userReducer(state = initialUserState, action: any): UserState {
  return reducer(state, action);
}
