import { Project } from '@models';
import { createReducer, on } from '@ngrx/store';
import * as ProjectActions from '../actions/project.actions';

export interface ProjectState {
  data?: Project[];
  selectedProject: Project | null;
  loading: boolean;
  error: string;
}

export const initialProjectState: ProjectState = {
  selectedProject: null,
  loading: false,
  error: '',
};

const reducer = createReducer<ProjectState>(
  initialProjectState,
  on(ProjectActions.loadProjects, (state) => ({ ...state, loading: true })),
  on(ProjectActions.loadProjectsSuccess, (state, { data }) => ({ ...state, data: data, loading: false })),
  on(ProjectActions.loadProjectsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ProjectActions.loadProject, (state) => ({ ...state, loading: true })),
  on(ProjectActions.loadProjectSuccess, (state, { project }) => ({
    ...state,
    selectedProject: project,
    loading: false,
  })),
  on(ProjectActions.loadProjectFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ProjectActions.addProject, (state, { project }) => ({ ...state, loading: true })),
  on(ProjectActions.addProjectSuccess, (state, { project }) => ({
    ...state,
    data: state.data ? [...state.data, project] : [project],
    loading: false,
  })),
  on(ProjectActions.addProjectFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ProjectActions.updateProject, (state, { project }) => ({
    ...state,
    loading: true,
  })),
  on(ProjectActions.updateProjectSuccess, (state, { project }) => ({
    ...state,
    data: state.data?.map(p => p.id === project.id ? project : p),
    loading: false,
  })),
  on(ProjectActions.updateProjectFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ProjectActions.deleteProject, (state, { id }) => ({ ...state, loading: true })),
  on(ProjectActions.deleteProjectSuccess, (state, { id }) => ({
    ...state,
    data: state.data?.filter(p => p.id !== id),
    loading: false,
  })),
  on(ProjectActions.deleteProjectFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ProjectActions.loadProjectGroups, (state) => ({ ...state, loading: true })),
  on(ProjectActions.loadProjectGroupsSuccess, (state, data) => {
    return {
      ...state,
      selectedProject: { ...state.selectedProject, groups: data.groups },
      loading: false,
    };
  }),
  on(ProjectActions.loadProjectGroupsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ProjectActions.addProjectGroups, (state) => ({ ...state, loading: true })),
  on(ProjectActions.addProjectGroupsSuccess, (state, { data }) => ({
    ...state,
    selectedProject: { ...state.selectedProject, groups: data },
    loading: false,
  })),
  on(ProjectActions.addProjectGroupsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ProjectActions.deleteProjectGroups, (state) => ({ ...state, loading: true })),
  on(ProjectActions.deleteProjectGroupsSuccess, (state, { data }) => ({
    ...state,
    selectedProject: {
      ...state.selectedProject,
      groups: state.selectedProject?.groups?.filter(g => !data.some(dg => dg.id === g.id)),
    },
    loading: false,
  })),
  on(ProjectActions.deleteProjectGroupsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ProjectActions.loadProjectControllers, (state) => ({ ...state, loading: true })),
  on(ProjectActions.loadProjectControllersSuccess, (state, data) => {
    return {
      ...state,
      selectedProject: { ...state.selectedProject, controllers: data.controllers },
      loading: false,
    };
  }),
  on(ProjectActions.loadProjectControllersFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ProjectActions.addProjectControllers, (state) => ({ ...state, loading: true })),
  on(ProjectActions.addProjectControllersSuccess, (state, { data }) => ({
    ...state,
    selectedProject: { ...state.selectedProject, controllers: data },
    loading: false,
  })),
  on(ProjectActions.addProjectControllersFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ProjectActions.deleteProjectControllers, (state) => ({ ...state, loading: true })),
  on(ProjectActions.deleteProjectControllersSuccess, (state, { data }) => ({
    ...state,
    selectedProject: {
      ...state.selectedProject,
      controllers: state.selectedProject?.controllers?.filter(c => !data.some(dc => dc.id === c.id)),
    },
    loading: false,
  })),
  on(ProjectActions.deleteProjectControllersFailure, (state, { error }) => ({ ...state, loading: false, error })),
);

export function projectReducer(state = initialProjectState, action: any): ProjectState {
  return reducer(state, action);
}
