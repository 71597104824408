import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { Controller, Group, Project } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/store';
import { SharedService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as ProjectSelectors from '../../../../store/selectors/project.selectors';
import * as ProjectActions from '../../../../store/actions/project.actions';
import * as ControllerSelectors from '../../../../store/selectors/controller.selectors';
import * as ControllerActions from '../../../../store/actions/controller.actions';
import { AddOptionsDialogComponent, ConfirmDialogComponent, ConfirmDialogType } from '@components';
import { faCircleMinus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'project-controllers',
  templateUrl: './project-controllers.component.html',
  styleUrl: './project-controllers.component.scss'
})
export class ProjectControllersComponent implements OnInit, OnDestroy {
  projectControllers$?: Observable<Controller[] | undefined>;
  isLoading$?: Observable<boolean>;
  projectControllers: Controller[] = [];
  controllers: Controller[] = [];
  projectId?: number;
  project: Project = {} as Project;
  displayedColumns: string[] = ['name', 'serialNumber', 'dateAddedToProject', 'type', 'timestampCreated', 'actions'];
  dataSource = new MatTableDataSource<Controller>();
  private unsubscribe$ = new Subject<void>();

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.dataSource.sortingDataAccessor = this.sharedService.sortingDataAccessorForNestedProperties;
  };

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
  ) {
    this.store.pipe(select(ProjectSelectors.selectSelectedProject)).subscribe(project => this.project = project!);
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initSubscriptions(): void {
    this.route.parent?.paramMap.pipe(
      map(params => params.get('id')),
      tap(id => {
        if (id) {
          this.projectId = +id;
          this.projectControllers$ = this.store.pipe(
            select(ProjectSelectors.selectSelectedProjectControllers),
            tap(projectsControllers => {
              if (!projectsControllers) {
                this.store.dispatch(ProjectActions.loadProjectControllers({ projectId: +id }));
              }
            }),
            map(projectControllers => {
              this.projectControllers = (projectControllers || []).map(pc => ({ ...pc, notesCharLimit: 100 }));
              this.dataSource.data = this.projectControllers;
              return projectControllers;
            })
          );
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe();

    this.store.pipe(
      select(ControllerSelectors.selectControllers),
      tap(controllers => {
        if (!controllers || controllers.length === 0) {
          this.store.dispatch(ControllerActions.loadControllers());
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe(controllers => {
      this.controllers = controllers || [];
    });

    this.isLoading$ = this.store.pipe(
      select(ProjectSelectors.selectProjectIsLoading),
      takeUntil(this.unsubscribe$),
    );
  }

  showAddProjectControllersDialog(): void {
    const dialogRef = this.dialog.open(AddOptionsDialogComponent, {
      width: '400px',
      data: {
        options: this.controllers.filter(c => !this.projectControllers.some(pc => pc.id === c.id)),
        displayProperty: 'name',
        title: 'Select Controllers'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(ProjectActions.addProjectControllers({ projectId: this.projectId!, controllers: result }));
      }
    });
  }

  showRemoveDialog(controller: Controller): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: ConfirmDialogType.DELETE,
        title: 'Remove Controller',
        message: `Are you sure you want to remove controller \'${controller.name}\' from \'${this.project.name}\'?`,
        okText: 'Remove',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(ProjectActions.deleteProjectControllers({ projectId: this.project.id!, controllers: [controller] }));
      }
    });
  }

  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faCircleMinus = faCircleMinus;
}
