import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss'
})
export class SearchInputComponent implements AfterViewInit, OnChanges {
  @Input() initialValue: string = '';
  @Input() placeholder: string = 'Search...';
  @Output() searchInputChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('input') input: ElementRef | undefined;

  ngAfterViewInit(): void {
    this.input!.nativeElement.value = this.initialValue || '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialValue'] && !changes['initialValue'].firstChange) {
      this.setInputValue(changes['initialValue'].currentValue);
    }
  }

  emitSearchValue(event: Event): void {
    this.searchInputChange.emit((event.target as HTMLInputElement).value);
  }

  clearInput(): void {
    this.input!.nativeElement.value = '';
    this.searchInputChange.emit('');
  }

  private setInputValue(value: string): void {
    if (this.input) {
      this.input.nativeElement.value = value || '';
    }
  }
}
