import { Card } from "src/app/models";
import { createReducer, on } from '@ngrx/store';
import * as CardActions from '../actions/card.actions';

export interface CardState {
  data?: { count: number, data: Card[] };
  selectedCard: Card | null;
  loading: boolean;
  filters: any;
  error: string;
}

export const initialCardState: CardState = {
  selectedCard: null,
  loading: false,
  filters: {},
  error: '',
};

const reducer = createReducer<CardState>(
  initialCardState,
  on(CardActions.loadCards, (state) => ({ ...state, loading: true })),
  on(CardActions.loadCardsSuccess, (state, { data }) => ({ ...state, data: data, loading: false })),
  on(CardActions.loadCardsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(CardActions.updateCardFilters, (state, { filters }) => ({
    ...state,
    filters,
    loading: true,
  })),
  on(CardActions.loadCard, (state) => ({ ...state, loading: true })),
  on(CardActions.loadCardSuccess, (state, { card }) => ({
    ...state,
    selectedCard: card,
    loading: false,
  })),
  on(CardActions.loadCardFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(CardActions.addCard, (state) => ({ ...state, loading: true })),
  on(CardActions.addCardSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(CardActions.addCardFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(CardActions.updateCard, (state) => ({
    ...state,
    loading: true,
  })),
  on(CardActions.updateCardSuccess, (state, { card }) => ({
    ...state,
    loading: false,
  })),
  on(CardActions.updateCardFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(CardActions.deleteCard, (state) => ({ ...state, loading: true })),
  on(CardActions.deleteCardSuccess, (state, { id }) => ({
    ...state,
    loading: false,
  })),
  on(CardActions.deleteCardFailure, (state, { error }) => ({ ...state, loading: false, error })),

);

export function cardReducer(state = initialCardState, action: any): CardState {
  return reducer(state, action);
}
