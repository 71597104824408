import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { Group, User } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/store';
import { SharedService, UserService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as GroupSelectors from '../../../../store/selectors/group.selectors';
import * as GroupActions from '../../../../store/actions/group.actions';
import * as UserSelectors from '../../../../store/selectors/user.selectors';
import * as UserActions from '../../../../store/actions/user.actions';
import { AddOptionsDialogComponent, ConfirmDialogComponent, ConfirmDialogType } from '@components';
import { faCircleMinus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'group-users',
  templateUrl: './group-users.component.html',
  styleUrl: './group-users.component.scss'
})
export class GroupUsersComponent implements OnInit, OnDestroy {
  groupUsers$?: Observable<User[] | undefined>;
  isLoading$?: Observable<boolean>;
  groupUsers: User[] = [];
  users: User[] = [];
  groupId?: number;
  group: Group = {} as Group;
  displayedColumns: string[] = ['name', 'phone', 'createdByAccount.username', 'notes', 'timestampCreated', 'actions'];
  dataSource = new MatTableDataSource<User>();
  private unsubscribe$ = new Subject<void>();

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.dataSource.sortingDataAccessor = this.sharedService.sortingDataAccessorForNestedProperties;
  };

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    private userService: UserService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
  ) {
    this.store.pipe(select(GroupSelectors.selectSelectedGroup)).subscribe(group => this.group = group!);
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initSubscriptions(): void {
    this.route.parent?.paramMap.pipe(
      map(params => params.get('id')),
      tap(id => {
        if (id) {
          this.groupId = +id;
          this.groupUsers$ = this.store.pipe(
            select(GroupSelectors.selectSelectedGroupUsers),
            tap(groupUsers => {
              if (!groupUsers) {
                this.store.dispatch(GroupActions.loadGroupUsers({ groupId: +id }));
              }
            }),
            map(groupUsers => {
              this.groupUsers = (groupUsers || []).map(gu => ({ ...gu, notesCharLimit: 100 }));
              this.dataSource.data = this.groupUsers;
              return groupUsers;
            })
          );
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe();

    this.store.pipe(
      select(UserSelectors.selectUsers),
      tap(users => {
        if (!users || users.length === 0) {
          this.store.dispatch(UserActions.loadUsers({}));
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe(users => {
      this.users = users || [];
    });

    this.isLoading$ = this.store.pipe(
      select(GroupSelectors.selectGroupIsLoading),
      takeUntil(this.unsubscribe$),
    );
  }

  showAddGroupUsersDialog(): void {
    const dialogRef = this.dialog.open(AddOptionsDialogComponent, {
      width: '600px',
      data: {
        options$: this.userService.getUsers.bind(this.userService),
        displayProperty: 'name',
        title: 'Select Users'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(GroupActions.addGroupUsers({ groupId: this.groupId!, users: result }));
      }
    });
  }

  showRemoveDialog(user: User): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: ConfirmDialogType.DELETE,
        title: 'Remove User',
        message: `Are you sure you want to remove user \'${user.name}\' from \'${this.group.name}\'?`,
        okText: 'Remove',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(GroupActions.deleteGroupUsers({ groupId: this.group.id!, users: [user] }));
      }
    });
  }

  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faCircleMinus = faCircleMinus;


}
