import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ProjectActions from '../actions/project.actions';
import * as ControllerActions from '../actions/controller.actions';
import { catchError, map, mergeMap, of } from 'rxjs';
import { ProjectService, SharedService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class ProjectEffects {

  loadProjects$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectActions.loadProjects),
    mergeMap(() => this.projectService.getProjects().pipe(
      map((data) => ProjectActions.loadProjectsSuccess({ data })),
      catchError(error => of(ProjectActions.loadProjectsFailure({ error }))),
    )),
  ));

  loadProject$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectActions.loadProject),
    mergeMap(action => this.projectService.getProject(action.id).pipe(
      map((project) => ProjectActions.loadProjectSuccess({ project })),
      catchError(error => of(ProjectActions.loadProjectFailure({ error }))),
    )),
  ));

  addProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.addProject),
      mergeMap(action =>
        this.projectService.addProject(action.project).pipe(
          map(response => {
            // Assuming response contains the newly added project
            this.sharedService.showSuccessSnackbar('Project added successfully');
            return ProjectActions.addProjectSuccess({ project: response });
          }),
          catchError(error => of(ProjectActions.addProjectFailure({ error }))),
        ),
      ),
    ),
  );

  updateProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.updateProject),
      mergeMap(action =>
        this.projectService.updateProject(action.project).pipe(
          map(response => {
            // Assuming response contains the updated project
            this.sharedService.showSuccessSnackbar('Project updated successfully');
            return ProjectActions.updateProjectSuccess({ project: response });
          }),
          catchError(error => of(ProjectActions.updateProjectFailure({ error }))),
        ),
      ),
    ),
  );

  deleteProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteProject),
      mergeMap(action =>
        this.projectService.deleteProject(action.id).pipe(
          map(() => {
            this.sharedService.showSuccessSnackbar('Project deleted successfully');
            return ProjectActions.deleteProjectSuccess({ id: action.id });
          }),
          catchError(error => of(ProjectActions.deleteProjectFailure({ error }))),
        ),
      ),
    ),
  );

  loadProjectGroups$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectActions.loadProjectGroups),
    mergeMap(action => this.projectService.getProjectGroups(action.projectId).pipe(
      map((data) => ProjectActions.loadProjectGroupsSuccess({ projectId: action.projectId, groups: data })),
      catchError(error => of(ProjectActions.loadProjectGroupsFailure({ error }))),
    )),
  ));

  addProjectGroups$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectActions.addProjectGroups),
    mergeMap(action => this.projectService.addProjectGroups(action.projectId, action.groups).pipe(
      map((data) => {
        this.sharedService.showSuccessSnackbar('Groups added successfully');
        return ProjectActions.addProjectGroupsSuccess({ data });
      }),
      catchError(error => of(ProjectActions.addProjectGroupsFailure({ error }))),
    )),
  ));

  deleteProjectGroups$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectActions.deleteProjectGroups),
    mergeMap(action => this.projectService.deleteProjectGroups(action.projectId, action.groups).pipe(
      map(() => {
        this.sharedService.showSuccessSnackbar('Group removed successfully');
        return ProjectActions.deleteProjectGroupsSuccess({ data: action.groups });
      }),
      catchError(error => of(ProjectActions.deleteProjectGroupsFailure({ error }))),
    )),
  ));

  loadProjectControllers$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectActions.loadProjectControllers),
    mergeMap(action => this.projectService.getProjectControllers(action.projectId).pipe(
      map((data) => ProjectActions.loadProjectControllersSuccess({ projectId: action.projectId, controllers: data })),
      catchError(error => of(ProjectActions.loadProjectControllersFailure({ error }))),
    )),
  ));

  addProjectControllers$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectActions.addProjectControllers),
    mergeMap(action => this.projectService.addProjectControllers(action.projectId, action.controllers).pipe(
      mergeMap((data) => {
        this.sharedService.showSuccessSnackbar('Controller removed successfully');
        return [
          ControllerActions.resetState(),
          ProjectActions.addProjectControllersSuccess({ data }),
        ];
      }),
      catchError(error => of(ProjectActions.addProjectControllersFailure({ error }))),
    )),
  ));

  deleteProjectControllers$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectActions.deleteProjectControllers),
    mergeMap(action => this.projectService.deleteProjectControllers(action.projectId, action.controllers).pipe(
      mergeMap(() => {
        this.sharedService.showSuccessSnackbar('Controller removed successfully');
        return [
          ControllerActions.resetState(),
          ProjectActions.deleteProjectControllersSuccess({ data: action.controllers }),
        ];
      }),
      catchError(error => of(ProjectActions.deleteProjectControllersFailure({ error }))),
    )),
  ));

  constructor(
    private actions$: Actions,
    private projectService: ProjectService,
    private sharedService: SharedService,
  ) {
  }
}
