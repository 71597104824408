import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { ThemePalette } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs';
import { AppState } from './store/store';
import { Store } from '@ngrx/store';
import * as AccountActions from './store/actions/account.actions';
import * as AccountSelectors from './store/selectors/account.selectors';
import { Account } from './models';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  currentUrl: string = '/';
  user?: Account;
  appTitle = 'Access Control System';
  primaryColor: ThemePalette = 'primary';
  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>
  ) {
    this.setPageTitle();
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentUrl = event.url;
        if(!this.isLoginRoute() && !this.user) {
          this.store.dispatch(AccountActions.loadMe());
        }
      });

    this.store.select(AccountSelectors.selectMe).subscribe(me => {
      this.user = me;
    });
  }

  setPageTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),  // Track when navigation ends
        map(() => this.activatedRoute),                    // Get the current activated route
        map((route) => {
          while (route.firstChild) route = route.firstChild; // Go through the child routes
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)                    // Get the data property for the title
      )
      .subscribe((data: any) => {
        const pageTitle = data.title ? `${data.title} | ${this.appTitle}` : this.appTitle;
        this.titleService.setTitle(pageTitle);
      });
  }

  isLoginRoute(): boolean {
    return this.currentUrl.startsWith('/login');
  }

  logout() {
    localStorage.removeItem('token');
    this.store.dispatch(AccountActions.removeMe());
    this.router.navigate(['/login']);
  }

  protected readonly faSignOutAlt = faSignOutAlt;
}
