<div class="content">
  <ng-container *ngIf="{
        userCards: userCards$ | async,
        loading: isLoading$ | async
    } as data">
    <div class="loader" *ngIf="data.loading; else userCardsData">
      <mat-spinner diameter="50" color="primary"></mat-spinner>
    </div>
    <ng-template #userCardsData>
      <div class="flex justify-end mb-4">
        <button mat-raised-button color="primary" (click)="showAddUserCardsDialog()">
          <fa-icon class="icon" [icon]="faPlusCircle"></fa-icon>
          <span>Add Cards</span>
        </button>
      </div>
      <div *ngIf="data.userCards">
        <table mat-table [dataSource]="dataSource" matSort matSortActive="data" matSortDirection="asc">

          <!-- Name Column -->
          <ng-container matColumnDef="data">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by card number">Card Number</th>
            <td mat-cell *matCellDef="let userCard">
              {{ userCard.data }}
            </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by type">Type
            </th>
            <td mat-cell class="text-center" *matCellDef="let userCard">{{ userCard.type }}
            </td>
          </ng-container>

          <!-- Type Column -->
          <ng-container matColumnDef="notes">
            <th mat-header-cell class="text-center" *matHeaderCellDef>Notes</th>
            <td mat-cell class="notes-column text-center" *matCellDef="let userCard">
              <div
                (click)="userCard.notesCharLimit = 100 * (+!userCard.notesCharLimit)">{{ userCard.notes | charLimit: userCard.notesCharLimit }}
                <span *ngIf="userCard.notes?.length > 100 && !userCard.notesCharLimit">{{ '[-]' }}</span>
              </div>
            </td>
          </ng-container>

          <!-- Timestamp Created Column -->
          <ng-container matColumnDef="timestampCreated">
            <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by datetime created">Created At
            </th>
            <td mat-cell class="text-center"
                *matCellDef="let userCard">{{ userCard.timestampCreated | date: 'MMM dd, YYYY HH:mm' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell class="text-center" *matHeaderCellDef></th>
            <td mat-cell class="flex gap-x-1 text-center" *matCellDef="let userCard">
              <div class="text-sm">
                <button class="icon-button" mat-icon-button title="Remove" (click)="showRemoveDialog(userCard)">
                  <fa-icon class="remove-icon" [icon]="faCircleMinus"></fa-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <!-- Column definitions -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="no-data-cell" [attr.colspan]="displayedColumns.length">
              No Results
            </td>
          </tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

      </div>
    </ng-template>
  </ng-container>
</div>
