import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ControllerService, SharedService } from '@services';
import * as ControllerActions from '../actions/controller.actions';
import { catchError, map, mergeMap, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ControllerEffects {

  loadControllers$ = createEffect(() => this.actions$.pipe(
    ofType(ControllerActions.loadControllers),
    mergeMap(() => this.controllerService.getControllers().pipe(
      map((data) => ControllerActions.loadControllersSuccess({ data })),
      catchError(error => of(ControllerActions.loadControllersFailure({ error })))
    ))
  ));

  loadController$ = createEffect(() => this.actions$.pipe(
    ofType(ControllerActions.loadController),
    mergeMap(action => this.controllerService.getController(action.id).pipe(
      map((controller) => ControllerActions.loadControllerSuccess({ controller })),
      catchError(error => of(ControllerActions.loadControllerFailure({ error })))
    ))
  ));

  addController$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ControllerActions.addController),
      mergeMap(action =>
        this.controllerService.addController(action.controller).pipe(
          map(response => {
            // Assuming response contains the newly added Controller
            this.sharedService.showSuccessSnackbar('Controller added successfully');
            return ControllerActions.addControllerSuccess({ controller: response });
          }),
          catchError(error => of(ControllerActions.addControllerFailure({ error })))
        )
      )
    )
  );

  updateController$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ControllerActions.updateController),
      mergeMap(action =>
        this.controllerService.updateController(action.controller).pipe(
          map(response => {
            // Assuming response contains the updated Controller
            this.sharedService.showSuccessSnackbar('Controller updated successfully');
            return ControllerActions.updateControllerSuccess({ controller: response });
          }),
          catchError(error => of(ControllerActions.updateControllerFailure({ error })))
        )
      )
    )
  );

  deleteController$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ControllerActions.deleteController),
      mergeMap(action =>
        this.controllerService.deleteController(action.id).pipe(
          map(() => {
            this.sharedService.showSuccessSnackbar('Controller deleted successfully');
            return ControllerActions.deleteControllerSuccess({ id: action.id });
          }),
          catchError(error => of(ControllerActions.deleteControllerFailure({ error })))
        )
      )
    )
  );

  updateControllerActivation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ControllerActions.updateControllerActivation),
      mergeMap(action =>
        this.controllerService.updateControllerActivation(action.id).pipe(
          map((response) => {
            this.sharedService.showSuccessSnackbar('Controller activation updated successfully');
            return ControllerActions.updateControllerActivationSuccess({controller: response});
          }),
          catchError(error => of(ControllerActions.updateControllerActivationFailure({ error })))
        )
      )
    )
  );

  loadControllerDoors$ = createEffect(() => this.actions$.pipe(
    ofType(ControllerActions.loadControllerDoors),
    mergeMap(action => this.controllerService.getControllerDoors(action.controllerId).pipe(
      map((doors) => ControllerActions.loadControllerDoorsSuccess({ controllerId: action.controllerId, doors })),
      catchError(error => of(ControllerActions.loadControllerDoorsFailure({ error })))
    ))
  ));

  addControllerDoor$ = createEffect(() => this.actions$.pipe(
    ofType(ControllerActions.addControllerDoor),
    mergeMap(action => this.controllerService.addControllerDoor(action.door.controllerId!, action.door).pipe(
      map((data) => ControllerActions.addControllerDoorSuccess({ data })),
      catchError(error => of(ControllerActions.addControllerDoorFailure({ error })))
    ))
  ));

  deleteControllerDoor$ = createEffect(() => this.actions$.pipe(
    ofType(ControllerActions.deleteControllerDoor),
    mergeMap(action => this.controllerService.deleteControllerDoor(action.controllerId, action.doorId).pipe(
      map(() => ControllerActions.deleteControllerDoorSuccess({ doorId: action.doorId })),
      catchError(error => of(ControllerActions.deleteControllerDoorFailure({ error })))
    ))
  ));

  constructor(
    private actions$: Actions,
    private controllerService: ControllerService,
    private sharedService: SharedService
  ) { }

}
