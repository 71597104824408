<div class="content">
  <ng-container *ngIf="{
        projectGroups: projectGroups$ | async,
        loading: isLoading$ | async
    } as data">
    <div class="loader" *ngIf="data.loading; else projectGroupsData">
      <mat-spinner diameter="50" color="primary"></mat-spinner>
    </div>
    <ng-template #projectGroupsData>
      <div class="flex justify-end mb-4">
        <button mat-raised-button color="primary" (click)="showAddProjectGroupsDialog()">
          <fa-icon class="icon" [icon]="faPlusCircle"></fa-icon>
          <span>Add Groups</span>
        </button>
      </div>
      <div *ngIf="data.projectGroups">
        <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Name</th>
            <td mat-cell *matCellDef="let projectGroup">
              <a [routerLink]="['/groups', projectGroup.id]">{{ projectGroup.name }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="createdByAccount.username">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by creator">Created By
            </th>
            <td mat-cell *matCellDef="let projectGroup">{{ (projectGroup.createdByAccount?.type == 'PERSON') ? projectGroup.createdByAccount?.username : projectGroup.createdByAccount?.name }}</td>
          </ng-container>

          <!-- Type Column -->
          <ng-container matColumnDef="notes">
            <th mat-header-cell class="text-center" *matHeaderCellDef>Notes</th>
            <td mat-cell class="notes-column text-center" *matCellDef="let projectGroup">
              <div
                (click)="projectGroup.notesCharLimit = 100 * (+!projectGroup.notesCharLimit)">{{ projectGroup.notes | charLimit: projectGroup.notesCharLimit }}
                <span *ngIf="projectGroup.notes?.length > 100 && !projectGroup.notesCharLimit">{{ '[-]' }}</span>
              </div>
            </td>
          </ng-container>

          <!-- Timestamp Created Column -->
          <ng-container matColumnDef="timestampCreated">
            <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                sortActionDescription="Sort by datetime created">Created At
            </th>
            <td mat-cell class="text-center"
                *matCellDef="let projectGroup">{{ projectGroup.timestampCreated | date: 'MMM dd, YYYY HH:mm' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell class="text-center" *matHeaderCellDef></th>
            <td mat-cell class="flex gap-x-1 text-center" *matCellDef="let projectGroup">
              <div class="text-sm">
                <button class="icon-button" mat-icon-button title="Remove" (click)="showRemoveDialog(projectGroup)">
                  <fa-icon class="remove-icon" [icon]="faCircleMinus"></fa-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <!-- Column definitions -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="no-data-cell" [attr.colspan]="displayedColumns.length">
              No Results
            </td>
          </tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

      </div>
    </ng-template>
  </ng-container>
</div>
