<div class="select-multiple-popup">
  <mat-form-field appearance="fill" class="full-width" (click)="!disabled && openDialog()">
    <mat-label>{{ label }}</mat-label>
    <input matInput readonly [value]="getSelectedDisplay()" [required]="required"
           [disabled]="disabled" />
    <button mat-icon-button matSuffix *ngIf="hasSelection()" (click)="clearSelection()">
      <mat-icon>clear</mat-icon>
    </button>
    <mat-icon matSuffix *ngIf="!hasSelection()">keyboard_arrow_down</mat-icon>
  </mat-form-field>
</div>
