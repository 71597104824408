import { User } from "./user.model";

export type Card = {
  id?: number;
  data?: string;
  type?: string;
  notes?: string;
  timestampCreated?: string;
  notesCharLimit?: number;
  user?: User;
}
