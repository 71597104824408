import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faCircleMinus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { Group, Project } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/store';
import { SharedService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as ProjectSelectors from '../../../../store/selectors/project.selectors';
import * as GroupSelectors from '../../../../store/selectors/group.selectors';
import * as GroupActions from '../../../../store/actions/group.actions';
import * as ProjectActions from '../../../../store/actions/project.actions';
import { AddOptionsDialogComponent, ConfirmDialogComponent, ConfirmDialogType } from '@components';

@Component({
  selector: 'project-groups',
  templateUrl: './project-groups.component.html',
  styleUrl: './project-groups.component.scss'
})
export class ProjectGroupsComponent implements OnInit, OnDestroy {
  projectGroups$?: Observable<Group[] | undefined>;
  isLoading$?: Observable<boolean>;
  projectGroups: Group[] = [];
  groups: Group[] = [];
  projectId?: number;
  project: Project = {} as Project;
  displayedColumns: string[] = ['name', 'createdByAccount.username', 'notes', 'timestampCreated', 'actions'];
  dataSource = new MatTableDataSource<Group>();
  private unsubscribe$ = new Subject<void>();

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.dataSource.sortingDataAccessor = this.sharedService.sortingDataAccessorForNestedProperties;
  };

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
  ) {
    this.store.pipe(select(ProjectSelectors.selectSelectedProject)).subscribe(project => this.project = project!);
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initSubscriptions(): void {
    this.route.parent?.paramMap.pipe(
      map(params => params.get('id')),
      tap(id => {
        if (id) {
          this.projectId = +id;
          this.projectGroups$ = this.store.pipe(
            select(ProjectSelectors.selectSelectedProjectGroups),
            tap(projectsGroups => {
              if (!projectsGroups) {
                this.store.dispatch(ProjectActions.loadProjectGroups({ projectId: +id }));
              }
            }),
            map(projectGroups => {
              this.projectGroups = (projectGroups || []).map(pg => ({ ...pg, notesCharLimit: 100 }));
              this.dataSource.data = this.projectGroups;
              return projectGroups;
            })
          );
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe();

    this.store.pipe(
      select(GroupSelectors.selectGroups),
      tap(groups => {
        if (!groups || groups.length === 0) {
          this.store.dispatch(GroupActions.loadGroups());
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe(groups => {
      this.groups = groups || [];
    });

    this.isLoading$ = this.store.pipe(
      select(ProjectSelectors.selectProjectIsLoading),
      takeUntil(this.unsubscribe$),
    );
  }

  showAddProjectGroupsDialog(): void {
    const dialogRef = this.dialog.open(AddOptionsDialogComponent, {
      width: '600px',
      data: {
        options: this.groups.filter(g => !this.projectGroups.some(pg => pg.id === g.id)),
        displayFunction: this.sharedService.displayGroupOption,
        title: 'Select Groups'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(ProjectActions.addProjectGroups({ projectId: this.projectId!, groups: result }));
      }
    });
  }

  showRemoveDialog(group: Group): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: ConfirmDialogType.DELETE,
        title: 'Remove Group',
        message: `Are you sure you want to remove group \'${group.name}\' from \'${this.project.name}\'?`,
        okText: 'Remove',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(ProjectActions.deleteProjectGroups({ projectId: this.project.id!, groups: [group] }));
      }
    });
  }

  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faCircleMinus = faCircleMinus;

}
