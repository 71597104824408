import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Door } from '@models';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as ControllerActions from '../../store/actions/controller.actions';

@Component({
  selector: 'add-door-dialog',
  templateUrl: './add-door-dialog.component.html',
  styleUrl: './add-door-dialog.component.scss',
})
export class AddDoorDialogComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  door: Door;
  isNew: boolean;
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<AddDoorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public doorData: Door,
  ) {
    this.isNew = !doorData?.id;
    this.door = doorData || {};
  }

  ngOnInit(): void {

  }

  onSubmit(): void {
    if (!this.door.name) {
      return;
    }

    this.store.dispatch(ControllerActions.addControllerDoor({ door: this.door }));

    this.dialogRef.close(this.door);

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
