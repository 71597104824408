
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GroupState } from '../reducers/group.reducers';
import { Group } from '@models';

export const selectGroupState = createFeatureSelector<GroupState>('group');
export const selectGroups = createSelector(selectGroupState, (state: GroupState) => state.data);
export const selectSelectedGroup = createSelector(selectGroupState, (state: GroupState) => state.selectedGroup);
export const selectGroupIsLoading = createSelector(selectGroupState, (state: GroupState) => state.loading);
export const selectSelectedGroupProjects = createSelector(selectGroupState, (state: GroupState) => state.selectedGroup?.projects);
export const selectSelectedGroupUsers = createSelector(selectGroupState, (state: GroupState) => state.selectedGroup?.users);
// export const selectGroupById = (id: number) => createSelector(selectGroups, groups => groups.find((g: Group) => g.id === id));
