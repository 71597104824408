import { Account } from './account.model';
import { Group } from './group.model';
import { Card } from './card.model';

export type User = {
  id?: number;
  createdByAccount?: Account;
  name?: string;
  phone?: string;
  notes?: string;
  timestampCreated?: string;
  notesCharLimit?: number;
  groups?: Group[];
  cards?: Card[];
  isSuspended?: number;
}
