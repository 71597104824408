import { User } from "./user.model";
import { Controller } from './controller.model';

export type Activity = {
  id?: number;
  timestampCreated?: string;
  user?: string;
  site?: string;
  controllerSerialNumber?: string;
  cardData?: string;
  doorName: 'IN' | 'OUT';
  projectName?: string;
  data?: ActivityData;
};

interface ActivityData {
  deviceId?: number;
  event?: ActivityEvent;
}

interface ActivityEvent {
  card: number;
  direction?: {
    code?: number;
    direction?: 'in' | 'out';
  };
  granted?: boolean;
  reason?: {
    code?: number;
    reason?: string;
  };
  timestamp?: string;
  type?: {
    code?: number;
    event?: string;
  };
}
