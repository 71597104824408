<div class="page activities">
  <ng-container *ngIf="{
        activities: activities$ | async,
        count: count$ | async,
        loading: isLoading$ | async
    } as data">
    <div class="container">
      <div class="header">
        <div class="title">Activities</div>
        <div class="page-actions">
        </div>
      </div>
      <div class="content">
        <div class="flex justify-between gap-x-4 items-start">
          <div class="filters-container">
            <div class="filter">
              <mat-form-field appearance="fill">
                <mat-label>Projects</mat-label>
                <mat-select [(value)]="projectId" multiple (selectionChange)="onProjectChange()">
                  <div class="mat-select-search">
                    <search-input [placeholder]="''" (searchInputChange)="filterProjects($event)"></search-input>
                  </div>
                  <mat-option *ngFor="let project of filteredProjects" [value]="project.id">
                    {{ project.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="filter">
              <mat-form-field appearance="fill">
                <mat-label>Date From</mat-label>
                <input
                  matInput
                  [matDatepicker]="pickerFrom"
                  [(ngModel)]="dateFrom"
                  (dateChange)="onDateFromChange()"
                  [matDatepickerFilter]="dateFromFilter"
                />
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="filter">
              <mat-form-field appearance="fill">
                <mat-label>Date To</mat-label>
                <input
                  matInput
                  [matDatepicker]="pickerTo"
                  [(ngModel)]="dateTo"
                  (dateChange)="onDateToChange()"
                  [matDatepickerFilter]="dateToFilter"
                />
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="filter">
              <mat-radio-group [(ngModel)]="status" (change)="onStatusChange($event)">
                <mat-radio-button class="radio-button" value="valid">Valid</mat-radio-button>
                <mat-radio-button class="radio-button" value="invalid">Invalid</mat-radio-button>
                <mat-radio-button class="radio-button" value="all">All</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="clear-filters">
            <button mat-raised-button (click)="clearFilters()" *ngIf="areFiltersApplied()">
              <mat-icon>clear</mat-icon>
              <span>Clear Filters</span>
            </button>
          </div>
        </div>
        <search-input [initialValue]="filters.search" (searchInputChange)="updateSearchValue($event)"></search-input>
        <div class="loader" *ngIf="data.loading; else activitiesData">
          <mat-spinner diameter="50" color="primary"></mat-spinner>
        </div>
        <ng-template #activitiesData>
          <div *ngIf="data.activities">
            <table mat-table [dataSource]="data.activities">

              <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef>
                  Timestamp
                </th>
                <td mat-cell
                    *matCellDef="let activity">{{ activity.timestampCreated | date: 'MMM dd, YYYY HH:mm:ss': 'UTC' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="event">
                <th mat-header-cell class="text-center" *matHeaderCellDef>Event
                </th>
                <td mat-cell class="text-center" *matCellDef="let activity">
                  <div class="flex justify-center items-center">
                    <mat-icon class="text-red-400 text-lg mr-2" [matTooltip]="activity.data.event.reason.reason"
                              *ngIf="!activity.data.event.granted">error
                    </mat-icon>
                    {{ (activity.data.event.granted ? 'Valid ' : 'Invalid ') + 'card swipe' }}
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="user">
                <th mat-header-cell class="text-center" *matHeaderCellDef>User
                </th>
                <td mat-cell class="text-center"
                    *matCellDef="let activity">{{ activity?.user }}
                </td>
              </ng-container>

              <ng-container matColumnDef="site">
                <th mat-header-cell class="text-center" *matHeaderCellDef>Project</th>
                <td mat-cell class="text-center"
                    *matCellDef="let activity">{{ activity.projectName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="controller">
                <th mat-header-cell class="text-center" *matHeaderCellDef>Door</th>
                <td mat-cell class="text-center"
                    *matCellDef="let activity">{{ activity.doorName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell class="text-center" *matHeaderCellDef></th>
                <td mat-cell class="text-center" *matCellDef="let activity">
                  <div class="text-sm">
                    <!--                    <button class="icon-button" mat-icon-button title="Delete" (click)="showDeleteDialog(activity)">-->
                    <!--                      <fa-icon [icon]="faTrash"></fa-icon>-->
                    <!--                    </button>-->
                  </div>
                </td>
              </ng-container>

              <!-- Column definitions -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="no-data-cell" [attr.colspan]="displayedColumns.length">
                  No Results
                </td>
              </tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

          </div>
          <mat-paginator [pageSizeOptions]="[10, 20, 40, 100]"
                         [pageSize]="filters.pageSize"
                         [pageIndex]="filters.pageOffset"
                         [showFirstLastButtons]="true"
                         [length]="data.count"
                         (page)="onPageFiltersChange($event)"
                         aria-label="Select page of periodic elements">
          </mat-paginator>
        </ng-template>
      </div>
    </div>
  </ng-container>
</div>
